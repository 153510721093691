import * as React from "react"

type ButtonType = {
  children: React.ReactNode
  type?: "button" | "submit"
  onClick?: () => void
  extraStyle?: string
  disabled?: boolean
}

const Button = ({ children, type, onClick, disabled, extraStyle }: ButtonType) => {
  return (
    <button
      type={type ? type : "button"}
      onClick={onClick}
      className={`capitalize rounded-lg text-xs p-2 text-white font-bold cursor-pointer shadow-sm border bg-gradient-to-bl from-blue-400 to-blue-600 transition duration-150 transform md:hover:scale-95 ${disabled && 'md:hover:scale-100'} ${extraStyle}`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
