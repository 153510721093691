import * as React from "react"

import Button from "../components/Button"
import ExternalLink from "../components/ExternalLink"

const ContactPage = () => {
  const [name, setName] = React.useState({
    value: "",
    hasBeenBlurred: false,
    isError: false,
  })
  const [email, setEmail] = React.useState({
    value: "",
    hasBeenBlurred: false,
    isError: false,
  })
  const [message, setMessage] = React.useState({
    value: "",
    hasBeenBlurred: false,
    isError: false,
  })
  const [isFormComplete, setIsFormComplete] = React.useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false)
  const [isSubmissionError, setIsSubmissionError] = React.useState(false)

  const getEmailValidity = (email: string) => {
    let emailIsValid = true
    const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    if (!emailRegex.test(email.trim())) {
      emailIsValid = false
    }
    return emailIsValid
  }

  const areAllFieldsValid = () => {
    let areFieldsValid = true

    if (name.value.trim().length === 0) {
      areFieldsValid = false
      if (name.hasBeenBlurred) {
        setName(name => ({ ...name, isError: true }))
      }
    } else {
      setName(name => ({ ...name, isError: false }))
    }

    if (
      email.value.trim().length === 0 ||
      !getEmailValidity(email.value.trim())
    ) {
      areFieldsValid = false
      if (email.hasBeenBlurred) {
        setEmail(email => ({ ...email, isError: true }))
      }
    } else {
      setEmail(email => ({ ...email, isError: false }))
    }

    if (message.value.trim().length === 0) {
      areFieldsValid = false
      if (message.hasBeenBlurred) {
        setMessage(message => ({ ...message, isError: true }))
      }
    } else {
      setMessage(message => ({ ...message, isError: false }))
    }

    return areFieldsValid
  }

  const handleInputChange = event => {
    switch (event.target.name) {
      case "name":
        setName(name => ({ ...name, value: event.target.value }))
        break
      case "email":
        setEmail(email => ({ ...email, value: event.target.value }))
        break
      case "message":
        setMessage(message => ({ ...message, value: event.target.value }))
        break
    }
  }

  const handleBlur = event => {
    switch (event.target.name) {
      case "name":
        setName(name => ({ ...name, hasBeenBlurred: true }))
        break
      case "email":
        setEmail(email => ({ ...email, hasBeenBlurred: true }))
        break
      case "message":
        setMessage(message => ({ ...message, hasBeenBlurred: true }))
        break
    }
  }

  React.useEffect(() => {
    if (areAllFieldsValid()) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }, [
    name.value,
    name.hasBeenBlurred,
    email.value,
    email.hasBeenBlurred,
    message.value,
    message.hasBeenBlurred,
  ])

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = event => {
    event.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: name.value,
        email: email.value,
        message: message.value,
      }),
    })
      .then(res => {
        if (["4", "5"].includes(res.status.toString()[0])) {
          setIsSubmissionError(true)
        } else {
          setIsFormSubmitted(true)
        }
      })
      .catch(() => {
        setIsSubmissionError(true)
      })
  }

  const inputWrapperStyle = "input-wrapper w-full"

  const inputStyle = "input w-full"

  const errorStyle = "text-xs text-red-600 font-semibold pl-3 mt-1"

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full sm:w-2/3 space-y-6">
          {isFormSubmitted ? (
            <div className="flex justify-center mt-6">
              <p className="font-bold">
                Thanks for reaching out! I'll try to respond to your message as
                soon as possible.
              </p>
            </div>
          ) : (
            <>
              <div className="flex-col space-y-6 mt-3">
                <div className="flex justify-center">
                  <h2 className="page-heading">Contact Me</h2>
                </div>
                  <p className="text-sm sm:text-base">
                    You can also connect with me on{" "}
                    <ExternalLink href="https://www.linkedin.com/in/cary-stothart-ph-d-0102402a/">
                      LinkedIn
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink href="https://github.com/cstothart">
                      GitHub
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink href="https://www.researchgate.net/profile/Cary-Stothart">
                      ResearchGate
                    </ExternalLink>
                    , and{" "}
                    <ExternalLink href="https://scholar.google.com/citations?user=ebpys10AAAAJ&hl=en">
                      Google Scholar
                    </ExternalLink>
                    .
                  </p>
              </div>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="flex flex-col space-y-3"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:
                    <input name="bot-field" onChange={handleInputChange} />
                  </label>
                </p>
                <div>
                  <div className={inputWrapperStyle}>
                    <input
                      aria-label="name"
                      className={`${inputStyle}`}
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={name.value}
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                    />
                  </div>
                  {name.isError && (
                    <p className={errorStyle}>What is your name?</p>
                  )}
                </div>
                <div>
                  <div className={inputWrapperStyle}>
                    <input
                      aria-label="email"
                      className={`${inputStyle}`}
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email.value}
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                    />
                  </div>
                  {email.isError && (
                    <p className={errorStyle}>Please enter a valid email.</p>
                  )}
                </div>
                <div>
                  <div className={inputWrapperStyle}>
                    <textarea
                      aria-label="message"
                      className={`${inputStyle} h-40`}
                      name="message"
                      placeholder="Message"
                      value={message.value}
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  {message.isError && (
                    <p className={errorStyle}>What is your message?</p>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="flex justify-center">
                    {isFormComplete ? (
                      <Button extraStyle="w-full sm:w-48" type="submit">
                        Send
                      </Button>
                    ) : (
                      <Button
                        extraStyle="w-full sm:w-48 opacity-50 cursor-not-allowed"
                        disabled={true}
                        type="submit"
                      >
                        Send
                      </Button>
                    )}
                  </div>
                  {isSubmissionError && (
                    <div className="flex justify-center">
                      <p className="text-xs text-red-600 font-semibold">
                        Something went wrong when contacting the server.
                      </p>
                    </div>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ContactPage
